<template>
  <div class="cts_box stand-by">
    <h2>「ワンコイン体験入塾」にお手続きありがとうございました！</h2>
    <div class="inner">
      <p>
        体験入塾期間は、お申込み当月末の<span class="bold txt_bdr">{{ moment(trialLimitDate(), 'YYYY年M月D日 HH:mm') }}</span>までとなります。<br>
        この体験期間を過ぎますと、<span class="marker bold">自動的に「本科（月額払い）」へ入塾</span>となります。
      </p>
      <h3>＜クラスを「予科」や「本科プラス」に変更したい方＞</h3>
      <p>
        {{ moment(trialLimitDate(), 'YYYY年M月D日 HH:mm') }}までに、<router-link to="/customer/no_join/rank/change" class="bold">「クラス変更ページ」</router-link>
        よりお手続きしてください。これにより、{{ moment(current_user.join_at, 'YYYY年M月D日') }}からご希望のクラスでご受講いただけます。<br>
        なお、本科（月額払い）のままでよい方は特に手続きは不要です。
      </p>
      <h3>＜入塾をキャンセルしたい方＞</h3>
      <p>
        {{ moment(trialLimitDate(), 'YYYY年M月D日 HH:mm') }}までに、<router-link to="/customer/withdrawal" class="bold">「入塾キャンセル」</router-link>よりお手続きしてください。
        この手続きにより月謝等の決済は行われません。
        ただし、期日を過ぎた場合は自動的に入塾扱いとなり翌月初に登録クレジットカードより決済されますのでご注意下さい。
      </p>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  methods: {
    trialLimitDate() {
      const date = new Date()
      return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23)
    }
  }
}
</script>